.angular-editor-toolbar-set {
    .color-picker {
        .box{
            &.hue-alpha  {
                height: 45px;
            }
        }

        .hex-text {
            .box {
                padding: 0 24px 0px 8px !important;
            }
        }

        .button-area {
            padding: 0 6px 6px !important;
            button {
                margin-left: 10px !important;
            }
        }

        .type-policy {
            top: 184px !important;
        }
    }
}