/* airme palette */

// $mat-airme: (
//     A100: white, __<<ngThemingMigrationEscapedComment1>>__
//     A200: #8C8C8C, __<<ngThemingMigrationEscapedComment2>>__
//     A400: #FFA000, __<<ngThemingMigrationEscapedComment3>>__
//     A700: #EE4242, __<<ngThemingMigrationEscapedComment4>>__
//     contrast: (
//       A100: #333333,
//       A200: white,
//       A400: white,
//       A700: white,
//     )
//   );

$mat-airme-basic: (
  50 : #fef9e7,
  100 : #fcf0c2,
  200 : #fae799,
  300 : #f8dd70,
  400 : #f7d552,
  500 : #f5ce33,
  600 : #f4c92e,
  700 : #f2c227,
  800 : #f0bc20,
  900 : #eeb014,
  A100 : #ffffff,
  A200 : #8C8C8C,
  A300 : #737373,
  A400 : #404040,
  A700 : #E6E6E6,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A300 : #333333,
      A400 : #000000,
      A700 : #000000,
  )
);

$mat-airme-accent: (
  50 : #fff4e0,
  100 : #ffe3b3,
  200 : #ffd080,
  300 : #ffbd4d,
  400 : #ffae26,
  500 : #ffa000,
  600 : #ff9800,
  700 : #ff8e00,
  800 : #ff8400,
  900 : #ff7300,
  A100 : #ffffff,
  A200 : #fff7f2,
  A400 : #ffd8bf,
  A700 : #ffc9a6,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$mat-airme-warn: (
  50 : #fde8e8,
  100 : #fac6c6,
  200 : #f7a1a1,
  300 : #f37b7b,
  400 : #f15e5e,
  500 : #ee4242,
  600 : #ec3c3c,
  700 : #e93333,
  800 : #e72b2b,
  900 : #e21d1d,
  A100 : #ffffff,
  A200 : #ffe5e5,
  A400 : #ffb2b2,
  A700 : #ff9898,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$mat-airme-menu: (
  50 : #e7e7e7,
  100 : #c2c2c2,
  200 : #999999,
  300 : #4d4d4d,
  400 : #404040,
  500 : #333333,
  600 : #2e2e2e,
  700 : #272727,
  800 : #202020,
  900 : #141414,
  A100 : #ef6e6e,
  A200 : #ea4040,
  A400 : #f60000,
  A700 : #dd0000,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);