@use '@angular/material' as mat;
@import '@angular/material/_theming';
@include mat.core();
@import 'mat-airme';

$airme-app-basic:   mat.define-palette($mat-airme-basic);
$airme-app-accent:  mat.define-palette($mat-airme-accent);
$airme-app-warn:    mat.define-palette($mat-airme-warn);
$airme-app-menu:    mat.define-palette($mat-airme-menu);

// Create the theme object (a Sass map containing all of the palettes).
$airme-app-theme: mat.define-light-theme($airme-app-basic, $airme-app-accent, $airme-app-warn);

@include mat.all-component-themes($airme-app-theme);

$primary-text-color:    mat.get-color-from-palette($airme-app-basic, default);
$accent-bg-color:       mat.get-color-from-palette($airme-app-basic, default);
$secondary-text-color:  mat.get-color-from-palette($airme-app-basic, A200);
$main-backgroud:        mat.get-color-from-palette($airme-app-basic, A700);
$content-backgroud:     mat.get-color-from-palette($airme-app-basic, A100);
$tab-backgroud:         mat.get-color-from-palette($airme-app-basic, A200);
$focus-color:           rgba(255, 227, 179, 0.3);

$table-header-font-color:       rgba(0, 0, 0, 0.87);
$table-header-font-weight:        700;
$table-bg-accent:               #ededed;
$table-bg-hover:                rgba(0,0,0,.12);
$table-bg-active:               $table-bg-hover;

$base-font-size: 1rem;

$menu-width: 200px;
$menu-border-width: 15px;
$header-height: 53px;
$content-border-width: 5px;
$content-padding-width: 10px;


$nav-row-height: 28px;
$action-row-height: 35px;

$menu-color:            mat.get-color-from-palette($airme-app-basic, A400);
$menu-text-color:       mat.get-color-from-palette($airme-app-basic, A100);
$menu-color-trans-1:    mat.get-color-from-palette($airme-app-accent, A100);
$secondary-color:       mat.get-color-from-palette($airme-app-basic, lighter);

$severity-ok: mat.get-color-from-palette($airme-app-warn, default);
$severity-warn: mat.get-color-from-palette($airme-app-warn, default);
$severity-error: mat.get-color-from-palette($airme-app-warn, default);

$file-icons-32-path: "/img/file_icons/32px";
$file-icons-48-path: "/img/file_icons/48px";