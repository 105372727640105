/* -- Material Design Table style -------------- */

@import 'variables';

// Variables
// ---------------------

$table-cell-padding:            .3rem;
$table-condensed-cell-padding:  calc($table-cell-padding / 2);

$table-bg:                      #fff;
$table-border-color:            #d4d4d4;

// Mixins
// -----------------
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}
// Tables
//
// -----------------


// Baseline styles
.table {
  width: 100%;
  max-width: 100%;
  background-color: $table-bg;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .ngx-pagination  {
    .current {
        background: $accent-bg-color;
    }

    &.ng-star-inserted {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
    }
  }

  tfoot {
    .ngx-pagination  {
        &.ng-star-inserted {
            margin-top: 0.5rem;
        }
      }
  }

    .rotate {
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        transform: rotate(180deg);
    }

  > thead,
  > tbody,
  > tfoot {
    > tr {
      //@include transition(all .3s ease);
      display: inline-flex;
      flex-direction: row;
      > th {
            text-align: left;
            padding: $table-cell-padding;
            vertical-align: top;
            border-top: 0;
            //@include transition(all .3s ease);
            cursor: pointer;
            font-weight: normal;
            height: 26px;
            .sort-icon {
                position: absolute;
                //arrow moved from right to directly behind the column name
                top: 5px;
                width: 20px;
                background-color: rgba(255,255,255,0.4);
                .mat-icon {
                    width: 24px;
                }
            }
            &.Money, &.Number {
                text-align: right;

                > .sorting {
                    margin-right: 15px;
                    margin-left: -25px;
                }
            }
      }
      > td {
        text-align: left;
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: 0;
        // @include transition(all .3s ease);
        cursor: pointer;

        div.Money, div.Number {
            text-align: right;
        }

        button {
            font-size: 0.8rem;
            height: 28px;
            line-height: 28px;
            .mat-icon {
                height: 17px;
                font-size: 1rem;
            }
        }
      }

    }
  }
  tbody {
  tr:nth-child(odd) {
    > td:not(.status),
    > th {
        background-color: $table-bg-accent; // $table-bg-accent;
    }
  }
}
  > thead > tr > th {
    font-weight: $table-header-font-weight;
    color: $table-header-font-color;
    vertical-align: bottom;
    border-bottom: 2px solid rgba(0,0,0,.12);
    padding-bottom: 0;
    padding-top: 9px;
  }
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  > tbody + tbody {
    border-top: 1px solid rgba(0,0,0,.12);
  }

  .title {
      float: left;
      font-weight: bold;
  }

  // Nesting
  .table {
    background-color: $table-bg;
  }

  // Remove border
  .no-border {
    border: 0;
  }

  .exception {
    .exceptionTitle {
        width: 100%;
        text-align: center;
        margin: 50px auto;
        .warning-icon {
            position: relative;
            top: 5px;
        }
    }
    .warning-icon {
        color: $severity-warn;
    }
  }

  > tfoot {
      > tr {
        > td {
            padding: 0 $table-cell-padding;
        }
      }
  }
}

// Condensed table w/ half padding
.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        font-weight: normal;
        padding: $table-condensed-cell-padding;
      }
    }
  }
}


// Bordered version
//
// Add horizontal borders between columns.
.table-bordered {
  border: 0;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 0;
        border-bottom: 2px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}


// Hover effect
//
.table-hover {
  > tbody > tr:hover {
    > td:not(.status),
    > th {
      background-color: $focus-color;
    }
  }
}

table {
    &.scroll {
        width: 100%;
        /* Optional */
        /* border-collapse: collapse; */
        border-spacing: 0;
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        display: inline-flex;

        thead, tfoot {
            display: inline-flex;
            tr {
                width: 100%;
                display: inline-block;
            }

            &.column-head {
                display: inline-flex;
                flex-direction: row;

                tr {
                    display: flex;
                }
            }

            &.pager-head {
                .pager {
                    display: flex;
                    flex-direction: row;
                    padding-top: 17px;
                    font-weight: normal;
                    float: right;
                    &>* {
                        display: inline-block;
                    }
                }
                th {
                    display: block;
                    height: 45px;
                }

                .found-total {
                    display: flex;
                    flex-direction: row;
                    padding-top: 17px;
                    float: left;
                    font-weight: normal;
                }
            }
        }

        tbody {
            height: calc(100% - 180px);
            overflow-y: auto;
            overflow-x: hidden;
            display: block;
            &.loading {
                min-height: 250px;
                width: 100%;
                display: block;
                tr,
                td {
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    tbody td,
    thead th,
    tfoot th {
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        > div {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    tbody td>* {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.single-column {
        tbody,
        thead {
            td,
            th {
                display: inline-block;
            }
        }
    }

    tbody,
    thead {
        tr {
            width: 100%;
        }
    }

    &.paging-disabled {
        tbody {
            height: calc(100% - 132px);
        }
    }
}


// Responsive tables (vertical)
//
// Wrap your tables in `.table-responsive-vertical` and we'll make them mobile friendly
// by vertical table-cell display. Only applies <768px. Everything above that will display normally.
// For correct display you must add 'data-title' to each 'td'
.table-responsive-vertical {

    width: 100%;
    overflow: hidden;
    height: calc(100vh - 110px);
}

@supports (-moz-appearance:none) {
    .table-responsive-vertical {
        margin-bottom: -112px;
    }
}


  @media screen and (max-width: 768px) {

    // Tighten up spacing
    .table {
      margin-bottom: 0;
      background-color: transparent;
      > thead,
      > tfoot {
        display: none;
      }

      > tbody {
        display: block;

        > tr {
          display: block;
          border: 1px solid $table-border-color;
          border-radius: 2px;
          margin-bottom: $table-cell-padding;

          > td {
            background-color: $table-bg;
            display: block;
            vertical-align: middle;
            text-align: right;
          }
          > td[data-title]:before {
            content: attr(data-title);
            float: left;
            font-size: inherit;
            font-weight: $table-header-font-weight;
            color: $table-header-font-color;
          }
        }
      }
    }

    // Special overrides for shadows
    .shadow-z-1 {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      > .table > tbody > tr {
        border: none;
      }
    }

    // Special overrides for the bordered tables
    .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > tbody {
        > tr {
          > td {
            border: 0;
            border-bottom: 1px solid $table-border-color;
          }
          > td:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    // Special overrides for the striped tables
    .table-striped {
      > tbody > tr > td,
      > tbody > tr:nth-child(odd) {
          background-color: $table-bg;
      }
      > tbody > tr > td:nth-child(odd) {
          background-color: $table-bg-accent;
      }
    }

    // Special overrides for hover tables
    .table-hover {
      > tbody {
        > tr:hover > td:not(.status),
        > tr:hover {
            background-color: $focus-color;
        }
        > tr > td:hover:not(.status) {
            background-color: $focus-color;
        }
      }
    }
  }



// CSS/LESS Color variations
//
// --------------------------------


.table-striped.table-mc-red > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-red > tbody > tr:nth-child(odd) > th {
    background-color: #fde0dc;
}
.table-hover.table-mc-red > tbody > tr:hover > td,
.table-hover.table-mc-red > tbody > tr:hover > th {
    background-color: #f9bdbb;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-red > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-red > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-red > tbody > tr > td:nth-child(odd) {
        background-color: #fde0dc;
    }
    .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-red > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-red > tbody > tr > td:hover {
        background-color: #f9bdbb;
    }
}
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-pink > tbody > tr:nth-child(odd) > th {
    background-color: #fce4ec;
}
.table-hover.table-mc-pink > tbody > tr:hover > td,
.table-hover.table-mc-pink > tbody > tr:hover > th {
    background-color: #f8bbd0;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-pink > tbody > tr > td:nth-child(odd) {
        background-color: #fce4ec;
    }
    .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-pink > tbody > tr > td:hover {
        background-color: #f8bbd0;
    }
}
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-purple > tbody > tr:nth-child(odd) > th {
    background-color: #f3e5f5;
}
.table-hover.table-mc-purple > tbody > tr:hover > td,
.table-hover.table-mc-purple > tbody > tr:hover > th {
    background-color: #e1bee7;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-purple > tbody > tr > td:nth-child(odd) {
        background-color: #f3e5f5;
    }
    .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-purple > tbody > tr > td:hover {
        background-color: #e1bee7;
    }
}
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) > th {
    background-color: #ede7f6;
}
.table-hover.table-mc-deep-purple > tbody > tr:hover > td,
.table-hover.table-mc-deep-purple > tbody > tr:hover > th {
    background-color: #d1c4e9;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-deep-purple > tbody > tr > td:nth-child(odd) {
        background-color: #ede7f6;
    }
    .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-deep-purple > tbody > tr > td:hover {
        background-color: #d1c4e9;
    }
}
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-indigo > tbody > tr:nth-child(odd) > th {
    background-color: #e8eaf6;
}
.table-hover.table-mc-indigo > tbody > tr:hover > td,
.table-hover.table-mc-indigo > tbody > tr:hover > th {
    background-color: #c5cae9;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-indigo > tbody > tr > td:nth-child(odd) {
        background-color: #e8eaf6;
    }
    .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-indigo > tbody > tr > td:hover {
        background-color: #c5cae9;
    }
}
// .table-striped.table-mc-blue > tbody > tr:nth-child(odd) > td:not(.status),
// .table-striped.table-mc-blue > tbody > tr:nth-child(odd) > th {
//     background-color: #e7e9fd;
// }
.table-hover.table-mc-blue > tbody > tr:hover > td,
.table-hover.table-mc-blue > tbody > tr:hover > th {
    background-color: #d0d9ff;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-blue > tbody > tr > td:nth-child(odd) {
        background-color: #e7e9fd;
    }
    .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-blue > tbody > tr > td:hover {
        background-color: #d0d9ff;
    }
}
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > td:not(.status),
.table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) > th {
    background-color: #e1f5fe;
}
// .table-hover.table-mc-light-blue > tbody > tr:hover > td:not(.status),
// .table-hover.table-mc-light-blue > tbody > tr:hover > th {
//     background-color: $table-bg-hover;
// }
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-light-blue > tbody > tr > td:nth-child(odd) {
        background-color: #e1f5fe;
    }
    .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr:hover > td:not(.status),
    .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-light-blue > tbody > tr > td:hover:not(.status) {
        background-color: $table-bg-hover;
    }
}
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > td:not(.status),
.table-striped.table-mc-cyan > tbody > tr:nth-child(odd) > th {
    background-color: #e0f7fa;
}
.table-hover.table-mc-cyan > tbody > tr:hover > td,
.table-hover.table-mc-cyan > tbody > tr:hover > th {
    background-color: $table-bg-hover;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-cyan > tbody > tr > td:nth-child(odd) {
        background-color: #e0f7fa;
    }
    .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-cyan > tbody > tr > td:hover {
        background-color: $table-bg-hover;
    }
}
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-teal > tbody > tr:nth-child(odd) > th {
    background-color: #e0f2f1;
}
.table-hover.table-mc-teal > tbody > tr:hover > td,
.table-hover.table-mc-teal > tbody > tr:hover > th {
    background-color: $table-bg-hover;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-teal > tbody > tr > td:nth-child(odd) {
        background-color: #e0f2f1;
    }
    .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-teal > tbody > tr > td:hover {
        background-color: $table-bg-hover;
    }
}
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-green > tbody > tr:nth-child(odd) > th {
    background-color: #d0f8ce;
}
.table-hover.table-mc-green > tbody > tr:hover > td,
.table-hover.table-mc-green > tbody > tr:hover > th {
    background-color: #a3e9a4;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-green > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-green > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-green > tbody > tr > td:nth-child(odd) {
        background-color: #d0f8ce;
    }
    .table-responsive-vertical .table-hover.table-mc-green > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-green > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-green > tbody > tr > td:hover {
        background-color: #a3e9a4;
    }
}
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-light-green > tbody > tr:nth-child(odd) > th {
    background-color: #f1f8e9;
}
.table-hover.table-mc-light-green > tbody > tr:hover > td,
.table-hover.table-mc-light-green > tbody > tr:hover > th {
    background-color: #dcedc8;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-light-green > tbody > tr > td:nth-child(odd) {
        background-color: #f1f8e9;
    }
    .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-light-green > tbody > tr > td:hover {
        background-color: #dcedc8;
    }
}
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-lime > tbody > tr:nth-child(odd) > th {
    background-color: #f9fbe7;
}
.table-hover.table-mc-lime > tbody > tr:hover > td,
.table-hover.table-mc-lime > tbody > tr:hover > th {
    background-color: #f0f4c3;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-lime > tbody > tr > td:nth-child(odd) {
        background-color: #f9fbe7;
    }
    .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-lime > tbody > tr > td:hover {
        background-color: #f0f4c3;
    }
}
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-yellow > tbody > tr:nth-child(odd) > th {
    background-color: #fffde7;
}
.table-hover.table-mc-yellow > tbody > tr:hover > td,
.table-hover.table-mc-yellow > tbody > tr:hover > th {
    background-color: #fff9c4;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-yellow > tbody > tr > td:nth-child(odd) {
        background-color: #fffde7;
    }
    .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-yellow > tbody > tr > td:hover {
        background-color: #fff9c4;
    }
}
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-amber > tbody > tr:nth-child(odd) > th {
    background-color: #fff8e1;
}
.table-hover.table-mc-amber > tbody > tr:hover > td,
.table-hover.table-mc-amber > tbody > tr:hover > th {
    background-color: #ffecb3;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-amber > tbody > tr > td:nth-child(odd) {
        background-color: #fff8e1;
    }
    .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-amber > tbody > tr > td:hover {
        background-color: #ffecb3;
    }
}
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-orange > tbody > tr:nth-child(odd) > th {
    background-color: #fff3e0;
}
.table-hover.table-mc-orange > tbody > tr:hover > td,
.table-hover.table-mc-orange > tbody > tr:hover > th {
    background-color: #ffe0b2;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-orange > tbody > tr > td:nth-child(odd) {
        background-color: #fff3e0;
    }
    .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-orange > tbody > tr > td:hover {
        background-color: #ffe0b2;
    }
}
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > td,
.table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) > th {
    background-color: #fbe9e7;
}
.table-hover.table-mc-deep-orange > tbody > tr:hover > td,
.table-hover.table-mc-deep-orange > tbody > tr:hover > th {
    background-color: #ffccbc;
}
@media screen and (max-width: 767px) {
    .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr > td,
    .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr:nth-child(odd) {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-striped.table-mc-deep-orange > tbody > tr > td:nth-child(odd) {
        background-color: #fbe9e7;
    }
    .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr:hover > td,
    .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr:hover {
        background-color: $table-bg;
    }
    .table-responsive-vertical .table-hover.table-mc-deep-orange > tbody > tr > td:hover {
        background-color: #ffccbc;
    }
}