@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(/fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(/fonts/MaterialIcons-Regular.woff) format('woff'),
       url(/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.icon-flipped {
    &-vertical {
      -o-transform: scaleY(-1);
      -moz-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      -ms-transform: scaleY(-1);
      transform: scaleY(-1);
    }

    &-horizontal {
      -o-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
      transform: scaleX(-1);
    }
}